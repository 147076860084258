import React from 'react';
import '../App.css';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../img/nombre.png';
import Lef from './Lef';

const Header = () => {
  
    return (
            <header className="mb-7">
                <nav className="navbar navbar-expand-lg navbar-dark bg-info fixed-top pe-5 ps-5">
                <a href="https://beatrizfuentes.com" className="navbar-brand">
                        <img src={logo} height="30" alt="" loading="lazy" />
                    </a>
                    <button className="navbar-toggler border-white color-white" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                        <ul className="navbar-nav text-center align-items-end">
                            <li className="nav-item active">
                                <a href="https://beatrizfuentes.com" className="nav-link text-light">Inicio</a>
                            </li>
                            <li className="nav-item active">
                                <a href="https://beatrizfuentes.com/libros" className="nav-link text-light">Libros</a>
                            </li>
                        </ul>
                    </div>
                </nav>
		<Lef/>
            </header>
    );
}
export default Header;