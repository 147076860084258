import { faFacebookSquare, faInstagram, faTiktok, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
            <footer className="bg-info p-3 text-center text-light mt-5">
                <a href="# " className="cursor-pointer float-end text-white text-decoration-none"><FontAwesomeIcon icon={faArrowAltCircleUp} /></a>
                <a className="text-light text-decoration-none me-1" href="https://facebook.com/BeatrizFuentesLugo" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                <a className="text-light text-decoration-none me-1" href="https://instagram.com/_beatrizfuentes" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                <a className="text-light text-decoration-none me-1" href="https://www.tiktok.com/@lasaventurasdefroilan" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTiktok} /></a>
                <a className="text-light text-decoration-none me-1" href="https://www.youtube.com/channel/UCOQEhsVE-yJJ5xvcCew3H0g" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
                <a className="text-light text-decoration-none" href="https://wa.me/523330151750" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a>
                <p>&copy;  Copyright {new Date().getFullYear()} Beatriz Fuentes </p>
            </footer>
        );
    }
}

export default Footer;