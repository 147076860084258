import React, { Fragment } from 'react';
import './App.css';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import './custom.scss';
import 'bootstrap/dist/js/bootstrap.min.js';

function App() {
  return (
    <Fragment>
      <Header />
      <Footer />
    </Fragment>
  );
}

export default App;
